
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        














































































































































































































.tv-this-week {
  // prettier-ignore
  @include fluid(padding-top, (s: 20px,xl: 110px));
  // prettier-ignore
  @include fluid(padding-bottom, (s: 20px,xl: 110px));

  position: relative;
  z-index: 2;
  overflow: hidden;
  color: $white;
}

.tv-this-week__title {
  margin-bottom: $spacing * 1.2;

  @include mq(l) {
    margin-bottom: $spacing * 2.7;
  }
}

.tv-this-week-inner {
  @include mq(l) {
    --slider-width: 70%;
    --slider-gap: #{$spacing * 2};

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 42rem;
    padding-right: calc(var(--slider-width) + var(--slider-gap));
  }

  @include mq(xl) {
    --slider-width: 80rem;

    min-height: 44.5rem;
  }

  @include mq(xxl) {
    --slider-width: 90rem;
    --slider-gap: #{$spacing * 4};
  }
}

.tv-this-week__controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $spacing;

  .tv-slider-controls {
    display: none;
  }

  @include mq(m) {
    justify-content: flex-start;
    margin-top: $spacing;

    .tv-slider-controls {
      display: flex;
    }
  }

  @include mq(l) {
    margin-top: 0;
  }
}

.tv-this-week__slider {
  @include mq(m) {
    position: relative;
  }

  @include mq(l) {
    position: absolute;
    top: 0;
    right: 0;
    width: var(--slider-width);
    height: 100%;
  }
}

.tv-this-week__item-outer {
  width: calc(100% - calc(var(--slide-spacing) * 2));
  height: fit-content;

  @include mq(l) {
    height: 100%;
  }
}

.tv-this-week__item {
  --diffusion-picture: 6.5rem;

  min-height: 100%;
  padding: $spacing * 0.5;
  background: rgba(196, 196, 196, 0.03);
  box-shadow: $box-shadow-lvl-1;
  border-radius: 1rem;

  @include mq(m) {
    display: grid;
    grid-template-rows: 1fr var(--diffusion-picture);
    grid-template-columns: minmax(40%, 40rem) minmax(30rem, 1fr);
    row-gap: $spacing * 1.5;
    column-gap: $spacing;
    margin: auto 0;
    padding: 0;
    background: none;
    box-shadow: none;
  }

  @include mq(xl) {
    grid-template-rows: 1fr calc(#{$spacing * 2.25} + var(--diffusion-picture));
    row-gap: 0;
    column-gap: $spacing * 2;
  }

  @include mq(xxl) {
    grid-template-columns: minmax(40%, 47rem) minmax(30rem, 1fr);
  }
}

.tv-this-week__item__details {
  @include mq(m) {
    display: flex;
    flex-direction: column;
    grid-row: 1 / 3;
    height: 100%;
    padding: $spacing * 0.5;
    background: rgba(196, 196, 196, 0.03);
    box-shadow: $box-shadow-lvl-1;
    border-radius: 1rem;
  }

  @include mq(xl) {
    align-self: stretch;
    padding: $spacing * 1.75;
  }
}

.tv-this-week__item__title {
  margin: 0;

  @include mq(xl) {
    font-size: 4.4rem;
  }
}

.tv-this-week__item__detail {
  @extend %text-uppercase;
  // prettier-ignore
  @include fluid(font-size, (s: 10px, l: 15px));

  // Clamp to 2 lines
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  // Clamp to 2 lines
  opacity: 0.5;
  font-weight: 400;
}

.tv-this-week__item__picture {
  position: relative;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 16/9;
  margin: $spacing * 0.5 0;
  border-radius: 1rem;

  img {
    @include image-fit;
  }

  @include mq(m) {
    order: -1;
    flex-shrink: 0;
    margin: 0 0 $spacing * 1.5;
  }
}

.tv-this-week__item__diffusions {
  @extend %list-nostyle;

  display: flex;
  flex-direction: column;
  gap: $spacing * 0.5 $spacing;

  @include mq(xxxs) {
    flex-direction: row;
  }

  @include mq(m) {
    grid-column: 2/3;
    grid-row: 2/3;
  }

  @include mq(xl) {
    margin-bottom: $spacing * 2.25;
  }
}

.tv-this-week__item__diffusion {
  // prettier-ignore
  @include fluid(font-size, (s: 12px, xxl: 18px));

  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;
}

.tv-this-week__item__diffusion__picture {
  position: relative;
  overflow: hidden;
  width: var(--diffusion-picture);
  height: var(--diffusion-picture);
  margin: 0 $spacing * 0.5 0 0;
  padding: $spacing * 0.5;
  background: $white;
  border-radius: 1rem;

  img {
    @include image-fit(contain);
  }
}

.tv-this-week__item__diffusion__content {
  flex: 1;
  text-transform: capitalize;
}

.tv-this-week__item__description {
  margin-top: $spacing;

  @include mq(m) {
    margin-top: 0;
  }

  @include mq(xl) {
    margin-top: $spacing;
  }
}

.tv-this-week__item__description {
  @include mq(xl) {
    margin-top: $spacing * 1.75;
  }
}

.tv-this-week__item__description__title {
  @extend %fw-semi;

  position: relative;
  display: inline-block;
  margin-bottom: $spacing * 0.75;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #d4107a;
  }

  @include mq(l) {
    font-size: 2.4rem;
    margin-bottom: $spacing;
  }
}

.tv-this-week__item__description__content {
  overflow-y: auto;
  max-height: 13rem;
  padding-right: $spacing * 0.5;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background-color: $c-anthracite-light;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $c-pink-medium;
  }

  @include mq(m) {
    max-height: 21rem;
  }
}
