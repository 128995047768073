
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        










































































































































































































































































































.channels-hero {
  --animation-fast: 15s;
  --animation-slow: 20s;

  // prettier-ignore
  @include fluid(margin-bottom, (s: 58px, l: 141px));

  position: relative;
  overflow: hidden; // Prevent overflow caused by title's backdrop
  margin-top: -10rem;

  @include mq(l) {
    margin-top: revert;
  }

  @include mq(xl) {
    --animation-fast: 20s;
    --animation-slow: 25s;
  }

  @include mq(xxl) {
    --animation-fast: 25s;
    --animation-slow: 30s;
  }
}

.channels-hero__inner {
  // prettier-ignore
  @include fluid(margin-top, (s: 207px, l: 290px));

  // prettier-ignore
  @include fluid(margin-bottom, (s: 216px, l: 267px));

  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.channels-hero__content {
  position: relative;
  text-align: center;

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 50%;
    width: 100%;
    height: 150%;
    transform: translateX(-50%) scale(2);
    background: radial-gradient(
      ellipse at center,
      rgb(8, 40, 51) 0,
      transparent 70%
    );
  }
}

.channels-hero__title {
  margin-top: 0;
  margin-bottom: 3rem;
  color: $white;
  line-height: 1.03;
  text-align: center;
  text-shadow: 0 0 20px #0817288c;
}

.channels-bg {
  position: absolute;
  width: 100%;
  height: 100%;
}

.channels-bg__layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: slide var(--animation-fast) linear infinite;

  &:nth-child(1),
  &:nth-child(3) {
    opacity: 0.5;
    animation: slide var(--animation-slow) linear infinite;
  }

  @include mq($until: l) {
    &:nth-child(3) {
      animation: slide var(--animation-slow) linear infinite reverse;
    }

    &:nth-child(4) {
      animation: slide var(--animation-fast) linear infinite reverse;
    }
  }
}

.channels-bg__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:nth-child(2) {
    transform: translateX(-100%);
  }

  &:nth-child(3) {
    transform: translateX(-200%);
  }
}

.channels-bg__item {
  // prettier-ignore
  @include fluid(width, (s: 80px, l: 127px));

  // prettier-ignore
  @include fluid(height, (s: 80px, l: 127px));

  position: absolute;
  background: $white;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;

  &.dummy {
    visibility: hidden;
  }
}

.channels-bg--background {
  opacity: 0.5;
  animation: slide 20s linear infinite;
}

.channels-hero__cta {
  ::v-deep .btn__icon {
    margin-left: 1rem;

    .icon {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
}

.robbie-tv-text {
  color: #fff;
}

@keyframes slide {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(100%);
  }
}
