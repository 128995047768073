
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        




















































.channels {
  position: relative;
  z-index: 1;
  min-height: 100vh;
  /* temporary */
  padding-top: 20rem;
  /* temporary */
  background: $c-promo-card;
}

.channels__discover {
  z-index: 1;
  background: $c-light-gray;
}
